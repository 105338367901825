import Papa from 'papaparse'

import { ReactNode, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { v4 as uuidv4 } from 'uuid';

import Dragger from 'antd/es/upload/Dragger'
import { Button, Checkbox, Form, InputNumber, message, Tooltip } from 'antd'

import type { UploadProps } from 'antd'

import Logo from './Assets/Logo10X.png'
import { ReactComponent as UploadIcon } from './Assets/Upload.svg'

import './App.scss'
import './Home.scss'

/**
 * LRE - Libro de Remuneraciones Electrónico
 *
 * @see https://static-content.api.dirtrab.cl/dt-docs/lre/lre_suplemento.pdf
 */
interface RecordRow {
  // Código tipo de jornada
  '1107': string
  // Sueldo
  '2101': string
  // Total haberes
  '5201': string
  // Total descuentos
  '5301': string
  // Total lÌquido
  '5501': string
}

function App() {
  const [searchParams, ] = useSearchParams() // setSearchParams
  
  // ?nombre=Julio+Mena&rut=18731198-5&mail=jmena%40contingente.cl
  const [nombre, ] = useState<string>(searchParams.get('nombre') || '')
  const [rut, ] = useState<string>(searchParams.get('rut') || '')
  const [mail, ] = useState<string>(searchParams.get('mail') || '')
  const [_remoteUuid, ] = useState<string>(searchParams.get('uuid') || '')
  
  useEffect(() => {
    if (!nombre || !rut || !mail) {
      // redirect to https://www.desafio10x.cl/#inicio_verifica
      window.location.href = 'https://www.desafio10x.cl/#inicio_verifica'
    }
    if (_remoteUuid?.length > 0) {
      setUuid(_remoteUuid)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const [type, setType] = useState<'lre' | 'lrem'>('lre')
  const [uploaded, setUploaded] = useState<boolean>(false)

  const [calculatedGap, setCalculatedGap] = useState<boolean>(false)
  const [calculatedMinimum, setCalculatedMinimum] = useState<boolean>(false)
  const [calculatedCost, setCaculatedCost] = useState<boolean>(false)

  const [formGap] = Form.useForm<{
    lowest: number
    highest: number
    gap: string
  }>()

  const [uuid, setUuid] = useState<string>(uuidv4())

  const gapLowest = Form.useWatch('lowest', formGap)
  const gapHighest = Form.useWatch('highest', formGap)

  const [okGap, setOkGap] = useState<boolean>(false)
  const [okUf, setOkUf] = useState<boolean>(false)
  
  const [formMinimum] = Form.useForm<{
    lowest: number
    uf: string
  }>()

  const [formCost] = Form.useForm<{
    workers: number
  }>()

  // External data from UF
  const [uf, setUf] = useState<number>(35000)
  const [fechaUf, setFechaUf] = useState<string>('referencialmente')

  // Data to calculate cost of verification
  const [workers, setWorkers] = useState<number>(0)
  const [base] = useState<number>(
    parseInt(process.env.REACT_APP_COSTO_BASE || '70000')
  )
  const [maxWorkers] = useState<number>(
    parseInt(process.env.REACT_APP_MAX_TRABAJADORES || '300')
  )
  const [costPerWorker] = useState<number>(
    parseInt(process.env.REACT_APP_COSTO_TRABAJADOR || '10000')
  )
  const [discountAssociated] = useState<number>(
    parseFloat(process.env.REACT_APP_DESCUENTO_ASOCIADO || '0.25')
  )
  const [discountEarlyAccess] = useState<number>(
    parseFloat(process.env.REACT_APP_DESCUENTO_ACCESO_ANTICIPADO || '0.4')
  )

  const [costFull, setCostFull] = useState<number>(0)
  const [costAssociated, setCostAssociated] = useState<number>(0)
  const [costEarlyAccess, setCostEarlyAccess] = useState<number>(0)

  const [gapHint, setGapHint] = useState<ReactNode>()
  const [minimumHint, setMinimumHint] = useState<ReactNode>()

  const [customQuote, setCustomQuote] = useState<number>(
    parseInt(`${process.env.REACT_APP_MINIMUM_QUOTE || 120000}`)
  )

  const [checkAssociated, setCheckAssociated] = useState<boolean>(true)

  const __gap = Form.useWatch('gap', formGap)
  const __uf = Form.useWatch('uf', formMinimum)

  async function postData(url = '', data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'no-cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        // 'Content-Type': 'application/json'
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
  }


  useEffect(() => {
    // When gap and minimum is calculated, check if it's ok
    if (__gap && __uf) {
      setUploaded(true)
      // parse __gap (remove X and parseFloat)
      const gap = parseFloat(__gap.replace('X', ''))
      // parse __uf (remove " UF" and parseFloat)
      const inUf = parseFloat(__uf.replace(' UF', ''))

      setOkGap(gap <= 10)
      setOkUf(inUf >= 22);

      (async () => {
        try {
          if (process.env.REACT_APP_ENDPOINT) {
            await postData(process.env.REACT_APP_ENDPOINT, {
                nombre,
                rut,
                mail,
                gapLowest,
                gapHighest,
                gap: __gap,
                ufCLP: uf * inUf,
                ufDate: uf,
                uf: __uf,
                uniqueKey: uuid,
              })
          }
        } catch (err) {
          //
        }
      })();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [__gap, __uf])

  useEffect(() => {
    ;(async () => {
      try {
        const data = await fetch('https://www.desafio10x.cl/uf/')
        const { UFs } = await data.json()
        const [last] = UFs
        setUf(parseFloat(last.Valor.replace('.', '').replace(',', '.')))
        setFechaUf(last.Fecha)
      } catch (err) {
        message.error(
          'No ha sido posible obtener el valor de la UF actualizado, se toma como referencia $35.000.'
        )
      }
    })()
  }, [])

  useEffect(() => {
    formCost.setFieldValue('workers', workers)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workers])

  const calculate = (data: RecordRow[], filename: string) => {
    // const [rut, ] = filename.replace('.csv', '').split('_')

    // const month = parse(`${_month}01`, 'yyyyMMdd', new Date(), {
    //   locale: es
    // })
    // console.log(month)

    // lowest salary not considering 0
    const lowestSalary = data.reduce<number>((acc: number, row: RecordRow) => {
      const salary = Number(row['5501'])
      if (salary > 0 && salary < acc) {
        return salary
      }
      return acc
    }, Number.MAX_SAFE_INTEGER)

    const highestSalary = data.reduce<number>((acc: number, row: RecordRow) => {
      const salary = Number(row['5501'])
      if (salary > acc) {
        return salary
      }
      return acc
    }, 0)

    updateBoxes({
      lowestSalary,
      highestSalary,
      dependencies: [setCalculatedGap, setCalculatedMinimum, setUploaded]
    })

    setWorkers(data.length)
  }

  const updateBoxes = ({
    lowestSalary,
    highestSalary,
    dependencies = []
  }: any) => {
    let _lowestSalary
    if (lowestSalary && highestSalary) {
      formGap.setFieldValue('lowest', lowestSalary)
      formGap.setFieldValue('highest', highestSalary)

      formMinimum.setFieldValue('lowest', lowestSalary)
      _lowestSalary = lowestSalary
    } else {
      lowestSalary = formGap.getFieldValue('lowest')
      highestSalary = formGap.getFieldValue('highest')
      _lowestSalary = formMinimum.getFieldValue('lowest')
    }

    const _gap = Math.round((highestSalary / lowestSalary) * 10) / 10
    const _uf = Math.round((_lowestSalary / uf) * 10) / 10

    if (isNaN(_gap) && dependencies.includes(setCalculatedGap)) {
      return message.error(
        'No se ha podido calcular la brecha salarial, ingresa un número válido.'
      )
    }

    if (isNaN(_uf) && dependencies.includes(setCalculatedMinimum)) {
      return message.error(
        'No se ha podido calcular el sueldo mínimo, ingresa un número válido.'
      )
    }

    formGap.setFieldValue('gap', `${_gap}X`)
    formMinimum.setFieldValue('uf', `${_uf} UF`)

    for (const _setter of dependencies) {
      _setter(true)
    }

    if (dependencies.includes(setCalculatedGap)) {
      setGapHint(
        <div className='ManualBox__Hint'>
          ¡Tu empresa{' '}
          {_gap <= 10 ? (
            <>
              cumple con el <br />
              <span className='font-bungee'>Desafío Brecha Salarial 10X</span>
            </>
          ) : (
            <strong>no cumple con el desafío</strong>
          )}
          !
        </div>
      )
    }

    if (dependencies.includes(setCalculatedMinimum)) {
      console.log('hint', _uf)
      setMinimumHint(
        <div className='ManualBox__Hint'>
          ¡Tu empresa{' '}
          {_uf >= 22 ? (
            <>
              cumple con el <br />
              <span className='font-bungee'>Desafío Sueldo Mínimo 22 UF</span>
            </>
          ) : (
            <strong>no cumple con el desafío</strong>
          )}
          !
        </div>
      )
    }
  }

  const props: UploadProps = {
    name: 'file',
    multiple: true,
    beforeUpload: (file) => {
      return false
    },
    onChange(info) {
      // @ts-ignore
      Papa.parse(info.file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          try {
            // @ts-ignore
            calculate(results.data, info.file.name)
          } catch (error) {
            console.error(error)
            message.error(
              `Error al procesar ${info.file.name}. Sube un LRE válido.`,
              10
            )
          }
        },
        transformHeader: function (header) {
          const letters = header.split('').reverse()
          const { code } = letters.reduce(
            (acc, letter, index) => {
              if (acc.finalize || letter === ')') {
                return acc
              }
              if (['('].includes(letter)) {
                acc.finalize = true
                return acc
              }
              acc.code = letter + acc.code
              return acc
            },
            {
              finalize: false,
              code: ''
            }
          )
          return code
        }
      })

      // const { status } = info.file;
      // if (status !== 'uploading') {
      //   console.log(info.file, info.fileList);
      // }
      // if (status === 'done') {
      //   message.success(`${info.file.name} file uploaded successfully.`);
      // } else if (status === 'error') {
      //   message.error(`${info.file.name} file upload failed.`);
      // }
    },
    onDrop(e) {
      //
    }
  }

  const calculateCost = ({ workers: _workersFromForm }: any) => {
    if (isNaN(_workersFromForm) || _workersFromForm < 1) {
      return message.error('Ingresa un número válido de trabajadores.')
    }
    setWorkers(_workersFromForm)
    setCaculatedCost(true)

    // Añadimos costo base por verificación.
    let _total = 0 + base

    // Calculamos el total de trabajadores a multiplicar, según el máximo.
    const _workers =
      _workersFromForm > maxWorkers ? maxWorkers : _workersFromForm

    // Sumamos al _total, el costo por el total de trabajadores.
    _total = _total + _workers * costPerWorker
    setCostFull(_total)

    // Calculamos el costo con descuento asociado.
    const _discountAssociated = _total - _total * discountAssociated
    setCostAssociated(_discountAssociated)

    // Calculamos el costo de acceso previo.
    const _discountEarlyAccess = _total - _total * discountEarlyAccess
    setCostEarlyAccess(_discountEarlyAccess)
  }

  return (
    <div className='App'>
      <img src={Logo} alt='Desafio 10X' className='Home__Logo' />
      <h1 className='Home__Title font-bungee'>1. Calcula tus desafíos</h1>
      {!uploaded && type === 'lre' && <Dragger {...props} className='Home__Dragger'>
        <p className='ant-upload-drag-icon'>
          <UploadIcon width={32} height={32} />
        </p>
        <p className='ant-upload-text'>
          Sube tus libros de remuneraciones electrónicos (LRE)
        </p>
        <p className='ant-upload-hint'>
          Archivo .CSV generados en formato de LRE. Los datos serán procesados
          en tu computador y no serán enviados a ningún servidor.
        </p>
      </Dragger>}
      <div className='Home__ManualCalc'>
        {!uploaded && <h2 className='Home__ManualCalc__Title showMouseClick' onClick={() => {
          // toggle type lre / lrem
          setType(type === 'lre' ? 'lrem' : 'lre')
        }}>
          También puedes <u>{type === 'lre' ? 'calcular manualmente' : 'subir libros de remuneraciones (LRE)'}</u>
        </h2>}
        {(uploaded || type === 'lrem') && <div className='Home__ManualCalc__Options'>
          <div className='Home__ManualCalc__Option'>
            <h3 className='Home__ManualCalc__Option__Title'>
              Desafío Brecha Salarial (10X)
            </h3>
            <Form
              form={formGap}
              layout='vertical'
              autoComplete='off'
              className='ManualBox'
            >
              <div className='ManualBox__Row'>
                <Form.Item name='lowest' label={<>Promedio 10% sueldos <br />más bajos</>}>
                  <InputNumber
                    disabled={calculatedGap}
                    // formatter={(value) => value!.toLocaleString('es-CL')}
                    // parser={(value) => parseInt(value!.replace('.', ''))}
                    decimalSeparator=','
                  />
                </Form.Item>
                <Form.Item
                  name='highest'
                  label={<>Promedio 10% <br />sueldos más altos</>}
                >
                  <InputNumber disabled={calculatedGap} />
                </Form.Item>
              </div>
              <div className='ManualBox__Row ManualBox__Row__Center'>
                {calculatedGap && (
                  <Form.Item name='gap' label='Tu brecha es de'>
                    <InputNumber className='ManualBox__Result' disabled />
                  </Form.Item>
                )}
              </div>
              {!calculatedGap && (
                <Form.Item className='ManualBox__Row__Center'>
                  <Button
                    type='primary'
                    htmlType='submit'
                    onClick={() => {
                      updateBoxes({
                        dependencies: [setCalculatedGap]
                      })
                    }}
                  >
                    Calcular
                  </Button>
                </Form.Item>
              )}
              {gapHint}
            </Form>
          </div>
          <div className='Home__ManualCalc__Option'>
            <h3 className='Home__ManualCalc__Option__Title'>
              Desafío Sueldo Mínimo (22 UF)
            </h3>
            <Form
              form={formMinimum}
              layout='vertical'
              autoComplete='off'
              className='ManualBox'
            >
              <div className='ManualBox__Row'>
                <Form.Item
                  name='lowest'
                  label='Sueldo mínimo (Total haberes en pesos)'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}
                >
                  <InputNumber
                    // formatter={(value) =>
                    //   parseInt(value!.toString()).toLocaleString('es-CL')
                    // }
                    // parser={(value) => parseInt(value!.replace('.', '')) || 0}
                    decimalSeparator=","
                    style={{
                      maxWidth: 220
                    }}
                    min={0}
                    disabled={calculatedMinimum}
                  />
                </Form.Item>
              </div>
              <div className='ManualBox__Row ManualBox__Row__Center'>
                {calculatedMinimum && (
                  <Form.Item
                    name='uf'
                    label={
                      <Tooltip
                        title={`El valor de la UF está calculado ${fechaUf} a un total de $${Math.round(
                          uf
                        ).toLocaleString('es-CL')}.`}
                      >
                        Tu sueldo mínimo es de *
                      </Tooltip>
                    }
                  >
                    <InputNumber
                      min={1}
                      className='ManualBox__Result'
                      disabled
                      value={
                        gapLowest && gapHighest
                          ? Math.round((gapHighest / gapLowest) * 100) / 100
                          : undefined
                      }
                    />
                  </Form.Item>
                )}
              </div>
              {!calculatedMinimum && (
                <Form.Item className='ManualBox__Row__Center'>
                  <Button
                    type='primary'
                    htmlType='submit'
                    onClick={() => {
                      updateBoxes({
                        dependencies: [setCalculatedMinimum]
                      })
                    }}
                  >
                    Calcular
                  </Button>
                </Form.Item>
              )}
              {minimumHint}
            </Form>
          </div>
        </div>}
      </div>
      {uploaded && !okGap && !okUf && <>
        <h1 className="Home__Title Home__Title__Failed font-bungee">Tu empresa no cumple con el Desafío 10X ni 22 UF</h1>
        <p className="Failed__Text">¡Sigue trabajando para aminorar la brecha salarial en tu empresa y subir los sueldos mínimos!</p>
      </>}
      {(okGap || okUf) && <>
        <h1 className='Home__Title font-bungee'>
          2. Calcula el costo de tu verificación
        </h1>
        <div className='Home__Cost'>
          <div className='Home__Cost__Box'>
            <Form
              form={formCost}
              layout='vertical'
              autoComplete='off'
              className='ManualBox'
              onFinish={(values) => {
                calculateCost(values)
              }}
            >
              <div className='ManualBox__Row'>
                <Form.Item name='workers' label='Número de trabajadores'>
                  <InputNumber
                    className='ManualBox__Result'
                    min={0}
                    disabled={calculatedCost}
                  />
                </Form.Item>
              </div>
            </Form>
          </div>
          <div className='Home__Cost__Box'>
            <span className='Home__Cost__Box__Title'>
              Costo de tu verificación
            </span>
            {!calculatedCost ? (
              <>
                <Button
                  type='primary'
                  htmlType='submit'
                  className='Home__Cost__Box__Submit'
                  onClick={() => {
                    formCost.submit()
                  }}
                >
                  Calcular
                </Button>
              </>
            ) : (
              <span className='Home__Cost__Box__Ammount Home__Cost__Box__Ammount__NoCheck'>
                ${costFull.toLocaleString('es-CL')}
              </span>
            )}
          </div>
          <div className='Home__Cost__Box'>
            <span className='Home__Cost__Box__Title'>Descuento Asociado Activo</span>
            {calculatedCost && (
              <>
                <span className='Home__Cost__Box__Ammount'>
                  ${costAssociated.toLocaleString('es-CL')}
                </span>
                <Checkbox checked={checkAssociated} onChange={(e) => {
                  setCheckAssociated(e.target.checked)
                }}>Quiero hacerme Asociado Activo</Checkbox>
              </>
            )}
            {!calculatedCost && (
              <span className='Home__Cost__Box__Ammount'>-</span>
            )}
          </div>
          <div className='Home__Cost__Box' style={{
            display: 'none'
          }}>
            <span className='Home__Cost__Box__Title'>Hasta el 28 de febrero</span>
            {calculatedCost && (
              <span className='Home__Cost__Box__Ammount  Home__Cost__Box__Ammount__NoCheck'>
                ${costEarlyAccess.toLocaleString('es-CL')}
              </span>
            )}
            {!calculatedCost && (
              <span className='Home__Cost__Box__Ammount'>-</span>
            )}
          </div>
          <div className='Home__Cost__Box'>
            <span className='Home__Cost__Box__Title' style={{
              display: 'inline'
            }}>
              Cuota anual socio activo<br />
              <span className='Home__Cost__Box__NormalWeight'>
                &nbsp;(desde $120.000)
              </span>
            </span>
            {calculatedCost && (
              <InputNumber
                onChange={(value) => {
                  if (value) {
                    setCustomQuote(parseInt(value.toString()))
                  }
                }}
                value={customQuote}
                // formatter={(value) =>
                //   parseInt(value?.toString() || '0').toLocaleString('es-CL')
                // }
                // parser={(value) => parseInt(value!.replace('.', ''))}
                className='Home__Cost__Box__CustomQuote Home__Cost__Box__Ammount'
                min={parseInt(`${process.env.REACT_APP_MINIMUM_QUOTE || 120000}`)}
              />
            )}
            {!calculatedCost && (
              <span className='Home__Cost__Box__Ammount'>-</span>
            )}
          </div>
          {calculatedCost && <>
          <div className="break"></div>
          <div className='Home__Cost__Box Home__Cost__CTA'>
              <Button size='large' type='primary' onClick={() => {
                // Open enrollment form
                window.open(process.env.REACT_APP_FORM_ENROLL || 'https://forms.gle/RCEHRvn4SMpeEBnf6', '_blank');

                // parse __uf (remove " UF" and parseFloat)
                const inUf = parseFloat(__uf.replace(' UF', ''));

                (async () => {
                  try {
                    if (process.env.REACT_APP_ENDPOINT) {
                      await postData(process.env.REACT_APP_ENDPOINT, {
                          nombre,
                          rut,
                          mail,
                          gapLowest,
                          gapHighest,
                          gap: __gap,
                          ufCLP: uf * inUf,
                          ufDate: uf,
                          uf: __uf,
                          uniqueKey: uuid,
                          workers,
                          associate: checkAssociated ? 'true' : 'false',
                          customQuote
                        })
                    }
                  } catch (err) {
                    //
                  }
                })();
              }}>Iniciar proceso de Verificación</Button>
              <Button size='large' type='primary' onClick={() => {
                // reload this page
                window.location.reload();
              }}>
                Volver a calcular
              </Button>
          </div>
          <div className="break"></div>
          <a className="Home__Cost__Link" href="https://www.desafio10x.cl/site/assets/files/1043/reglas-verificacion.pdf" download={true} target="_blank" rel="noreferrer">
            Descargar Reglas de Verificación
          </a>
          </>}
        </div>
      </>}
    </div>
  )
}

export default App
